/* You can add global styles to this file, and also import other style files */
@import 'app/layout/style';
@import 'styles/themes';
@import 'styles/custom';
@import 'styles/grid';
@import 'styles/helpers';
@import 'styles/plugins';

.oha-blue-bg {
  background-color: #005595;
}

.oha-orange-bg {
  background-color: #EC891D;
}

.download-container {
  display: flex;
  align-items: center;  
}

.spinner-container {
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
}
